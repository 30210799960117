import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_auto_size_panel_item_button = _resolveComponent("tm-auto-size-panel-item-button")!
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, {
    breadcrumbs: _ctx.breadcrumbs,
    "bg-shade": ""
  }, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    "filter-bar-dropdown": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-dropdown")
    ]),
    before: _withCtx(() => [
      _renderSlot(_ctx.$slots, "before")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_details_hero, {
        pattern: "green",
        icon: "domain",
        class: "mb-5",
        title: _ctx.hero.title,
        "avatar-outline": "",
        "avatar-color": _ctx.hero.avatarColor,
        tabs: _ctx.tabsRouter,
        ellipsis: ""
      }, {
        right: _withCtx(() => [
          _createVNode(_component_tm_auto_size_panel_item_button, {
            icon: "tmi-login",
            text: "Switch to this sub-account",
            onClick: _ctx.switchSubAccount
          }, null, 8, ["onClick"]),
          _createVNode(_component_tm_auto_size_panel_item_button, {
            icon: "edit",
            text: "Edit",
            onClick: _ctx.openEditSubAccountModal
          }, null, 8, ["onClick"]),
          _createVNode(_component_tm_auto_size_panel_item_button, {
            icon: "domain",
            text: "Make default",
            onClick: _ctx.openMakeDefaultModal
          }, null, 8, ["onClick"]),
          _createVNode(_component_tm_auto_size_panel_item_button, {
            icon: "delete",
            text: "Deactivate sub-account",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDeactivateSubAccountModal([])))
          })
        ]),
        _: 1
      }, 8, ["title", "avatar-color", "tabs"]),
      _createVNode(_component_router_view)
    ]),
    _: 3
  }, 8, ["breadcrumbs"]))
}