
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import { notify } from '@kyvg/vue3-notification'
import useWorkspace from '@/compositions/admin/useWorkspace'

export default defineComponent({
  components: {
    DetailsHero,
    TmAutoSizePanelItemButton,
    PageContent,
  },
  setup() {
    const { openDeactivateSubAccountModal, openMakeDefaultModal, openEditSubAccountModal } = useWorkspace()
    const breadcrumbs: BreadcrumbsLink[] = [
      { label: 'Sub-accounts', name: 'base.admin.subAccounts' },
      { label: 'Adela Test\'s sub-account' },
    ]
    const hero = ref({
      avatarColor: 'orange',
      title: 'Adela Test’s sub-account',
    })

    const tabsRouter = ref([
      {
        name: 'Overview',
        to: { name: 'base.admin.subAccounts.details.overview' },
      },
      {
        name: 'Audit logs',
        to: { name: 'base.admin.subAccounts.details.audit' },
      },
    ])
    const switchSubAccount = () => {
      notify({
        text: 'You have switched to Aleksei Kuznetsov’s sub-acccount.',
        type: 'info',
      })
    }

    return {
      breadcrumbs,
      openMakeDefaultModal,
      openEditSubAccountModal,
      openDeactivateSubAccountModal,
      switchSubAccount,
      tabsRouter,
      hero,
    }
  },
})
